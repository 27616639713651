//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



import { initMixin } from '@/mixins/initMixin'

const {required, sameAs, minLength, email, numeric} = require('vuelidate/lib/validators');
import { mapState } from 'vuex'
import fadeOutEffect from '~/utils/fadeout'
import { passwordValidators } from '~/utils/validators'

export default {
    name: 'Auth',
    mixins: [initMixin],
    head: {
        title: 'Authentication - ESSE',
    },
    data() {
        return {
            form: 'login',
            message: '',
            error: null,
            loading: {
                login: false,
                register: false,
                reset: false,
                recover: false
            },
            login: {
                email: '',
                password: '',
            },
            register: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                password: '',
                confirm_password: '',
            },
            reset: {
                user_type: 'client',
                email: '',
            },
            recover: {
                user_type: 'client',
                email: '',
                password: '',
                confirm_password: '',
                reset_token: ''
            },
            not_confirmed: false
        }
    },
    validations: {
        login: {
            email: {required: required},
            password: {required: required},
        },
        register: {
            first_name: {required: required, minLength: minLength(2)},
            last_name: {required: required, minLength: minLength(2)},
            email: {required: required, email},
            phone: {required: required, minLength: minLength(10), numeric},
            password: passwordValidators,
            confirm_password: {required, sameAs: sameAs('password')},
        },
        reset: {
            email: {required, email}
        },
        recover: {
            password: {required},
            confirm_password: {required, sameAs: sameAs('password')},
        },
    },
    watch:{
        $route (to){
            if (to.name === 'register') {
                this.form = 'register';
            } else if (to.name === 'login') {
                this.form = 'login';
            } else {
                this.form = to.name;
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    created: function () {
        if (this.$route.name === 'register') {
            this.form = 'register';
        }
        // $('body').removeClass('2-columns');
        // $('body').addClass('bg-full-screen-image blank-page 1-column');
        // $('body').attr('data-col', '1-column');
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from;
        });
    },
    mounted: function () {
        if (this.user.id) {
            this.$router.push({name: 'account'});
        } else {
            fadeOutEffect(document.getElementById('preloader'));
        }
        if (this.$route.query.resetToken) {
            this.form = 'recover';
            this.recover.email = this.$route.query.email;
            this.recover.reset_token = this.$route.query.resetToken;
        }
        if (this.$route.query.confirmToken) {
            this.confirmAccount();
        }
    },
    methods: {
        signIn: function () {
            this.$v.login.$touch();
            if (this.$v.login.$invalid == true) {
                return false;
            }
            this.loading.login = true;
            this.$axios.post("/auth/login", this.login)
                .then((res) => {
                    this.loading.login = false;
                    // console.log(this.$store)
                    if (res && res.token) {
                        this.$store.commit('setUser', res.user);
                        this.$store.commit('setActiveBookingsCount', res.user.active_bookings_count);
                        this.$auth.setToken(res.token);
                        this.$auth.setRole('client');
                        this.$acl.change('client');

                        setTimeout(() => {
                            if (this.prevRoute.name === 'register') {
                                this.$router.push({name: 'account'});
                                return;
                            }
                            if (this.prevRoute) {
                                this.$router.push(this.prevRoute);
                            } else {
                                this.$router.push({name: 'account'});
                            }
                        }, 200);
                    }
                })
                .catch((error) => {
                    if (error.response.data.not_confirmed) {
                        this.not_confirmed = true;
                    }
                    this.loading.login = false;
                });
        },
        signUp: function () {
            this.$v.register.$touch();
            if (this.$v.register.$invalid == true) {
                return false;
            }
            this.error = '';
            this.message = '';
            this.loading.register = true;
            this.$axios.post("/auth/register", this.register)
                .then((res) => {
                    this.loading.register = false;
                    if (res.token) {
                        this.$store.commit('setUser', res.user);
                        this.$store.commit('setActiveBookingsCount', res.user.active_bookings_count);
                        this.$auth.setToken(res.token);
                        this.$auth.setRole('client');
                        this.$acl.change('client');

                        this.$router.push({name: 'account'});
                    } else {
                        this.message = res.message;
                        this.register = {
                            first_name: '',
                            last_name: '',
                            email: '',
                            password: '',
                            confirm_password: '',
                            terms: false,
                        }
                        this.$v.register.$reset();
                        this.form = 'login'
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.loading.register = false;
                });
        },
        resetPassword: function () {
            this.$v.reset.$touch();
            if (this.$v.reset.$invalid == true) {
                return false;
            }
            this.loading.reset = true;
            this.$axios.post("/auth/recover", this.reset)
                .then((res) =>{
                    this.form = 'login';
                    this.loading.reset = false;
                })
                .catch((res) =>{
                    this.loading.reset = false;
                })
        },
        recoverPassword: function () {
            this.$v.recover.$touch();
            if (this.$v.recover.$invalid == true) {
                return false;
            }
            this.loading.recover = true;
            this.$axios.post("/auth/reset", this.recover)
                .then((res) =>{
                    this.loading.recover = false;
                    this.form = "login";
                    this.$router.replace(this.$router.currentRoute.path);
                })
                .catch((res) =>{
                    this.loading.recover = false;
                })
        },
        switchToLogin() {
            this.$router.push({ name: 'login'});
            this.form = 'login';
        },
        switchToRegister() {
            this.$router.push({ name: 'register'});
            this.form = 'register';
        },
        AuthProvider(provider) {
            this.$socialAuth.authenticate(provider).then(response =>{
                this.SocialLogin(provider,response)
            }).catch(err => {
                console.log({err:err})
            })
        },
        SocialLogin(provider,response){
            this.$axios.post(`/auth/login/${provider}/callback`, response)
                .then(res => {
                    if (res.token) {
                        this.$store.commit('setUser', res.user);
                        this.$store.commit('setActiveBookingsCount', res.user.active_bookings_count);
                        this.$auth.setToken(res.token);
                        // this.$auth.setRole('client');
                        this.$acl.change('client');
                        setTimeout(() => {
                            if (this.prevRoute.name === 'register') {
                                this.$router.push({name: 'account'});
                                return;
                            }
                            // console.log(this.prevRoute)
                            if (this.prevRoute) {
                                this.$router.push(this.prevRoute);
                            } else {
                                this.$router.push({name: 'account'});
                            }
                        }, 200);
                    }
                }).catch(err => {
                    console.log({err:err})
                })
        },
        confirmAccount () {
            this.loading.confirm = true;
            this.$axios.post("/auth/confirm-account", {
                confirmation_token: this.$route.query['confirmToken']
            })
                .then((res) =>{
                    this.loading.confirm = false;
                    this.$router.replace(this.$router.currentRoute.path);
                })
                .catch((res) =>{
                    this.loading.confirm = false;
                })
        },
        resendConfirmationLink() {
            this.loading.confirm = true;
            this.$axios.post("/auth/resend-confirmation-link", {
                email: this.login.email,
            })
                .then((res) =>{
                    this.loading.confirm = false;
                })
                .catch((res) =>{
                    this.loading.confirm = false;
                })
        }
    },
}
