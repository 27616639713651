//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { modelListMixin } from '~/mixins/modelListMixin'
import { mapState } from 'vuex'

export default {
    name: 'BookingHistory',
    mixins: [modelListMixin],
    head: {
        title: 'My Bookings history - ESSE',
    },
    data () {
        return {
            loading: {
                data: false,
                cancel: false,
            },
            past_bookings: [],
            future_bookings: {},
            selected_booking: null,
        }
    },
    computed: {
        ...mapState({
            userdata: state => state.user,
        })
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.post("/account/bookings", this.filters)
                .then((res) =>{
                    this.past_bookings = res.past_bookings;
                    this.future_bookings = res.future_bookings;
                    if (this.selected_booking) {
                        if(this.past_bookings.data.find(i => i.id === this.selected_booking.id)){
                            this.selectBooking(this.past_bookings.data.find(i => i.id === this.selected_booking.id))
                        } else if(this.future_bookings.find(i => i.id === this.selected_booking.id)){
                            this.selectBooking(this.future_bookings.find(i => i.id === this.selected_booking.id))
                        }
                    }
                    this.loading.data = false;
                })
                .catch((error) => {
                    console.log(error)
                    this.loading.data = false;
                });
        },
        selectBooking(booking) {
            this.selected_booking = JSON.parse(JSON.stringify(booking));
        },
        cancelBooking(booking) {
            this.loading.cancel = true;
            this.$axios.post("/account/booking/cancel", {id: booking.id})
                .then(() =>{
                    this.loading.cancel = false;
                    this.getData();
                    this.selectBooking(null);
                })
                .catch((error) => {
                    this.loading.cancel = false;
                });
        }
    },
};
