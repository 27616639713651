//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { modelListMixin } from '~/mixins/modelListMixin'

export default {
    name: 'MyReviews',
    mixins: [modelListMixin],
    head: {
        title: 'My Reviews - ESSE',
    },
    data () {
        return {
            loading: {
                data: false,
                save: false
            },
            reviews: [],
        }
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.post('/account/reviews', this.filters)
                .then((res) =>{
                    this.reviews = res.reviews;
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
    },
    mounted() {
        this.getData();
    }
};
