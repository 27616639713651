//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'Languages',
    head: {
        title: 'Language select - ESSE',
    },
    data () {
        return {

        }
    },
    methods: {
        goBack () {

        },
        selectLanguage (locale) {
            if (this.$i18n.locale !== locale) {
                this.$i18n.locale = locale
                this.$i18n.setLocaleCookie(locale)
                this.$events.$emit('changeLocale', locale)
            }
        },
    },
};
