//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const {required, sameAs, email} = require('vuelidate/lib/validators');
import { mapState } from 'vuex'
import { passwordValidators } from '~/utils/validators'

export default {
    name: 'AccountData',
    head: {
        title: 'My Account - ESSE',
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: 'User account'
            }
        ],
    },
    data () {
        return {
            loading: {
                data: true,
                info: false,
                address: false,
                password: false,
                email: false,
            },
            user: {
                id: null,
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
            },
            password: {
                password: '',
                confirm_password: '',
                current_password: '',
            },
            email: {
                old_password: '',
                email: '',
                confirm_email: '',
            },
            address: {
                street_no: '',
                street: '',
                city: '',
                zip_code: '',
            }
        }
    },
    validations:  {
        user: {
            first_name: {required},
            last_name: {required},
        },
        password: {
            current_password: {required},
            password: passwordValidators,
            confirm_password: {sameAs: sameAs('password')}
        },
        email: {
            current_password: {required},
            email: {required, email},
            confirm_email: {sameAs: sameAs('email')}
        },
        address: {
            street_no: {required},
            street: {required},
            city: {required},
        }
    },
    computed: {
        ...mapState({
            userdata: state => state.user,
        })
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.user.id = this.userdata.id;
            this.$axios.post("/account/info", {id: this.userdata.id})
                .then((res) =>{
                    if (res.user) {
                        this.user = res.user;
                    }
                    if (res.address) {
                        this.address = {...this.address, ...res.address};
                    }
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        save: function () {
            this.$v.user.$touch();
            if (this.$v.user.$invalid == true) {
                return false;
            }
            this.loading.info = true;
            this.$axios.post("/account/save", this.user)
                .then(() =>{
                    this.loading.info = false;
                    this.getData();
                    this.$store.commit('setUser', this.user);
                    this.$events.emit('loadUsers');
                })
                .catch((res) => {
                    this.loading.info = false;
                })
        },
        saveAddress:  function () {
            this.$v.address.$touch();
            if (this.$v.address.$invalid == true) {
                return false;
            }
            this.loading.address = true;
            this.$axios.post("/account/save-address", this.address)
                .then(() =>{
                    this.loading.address = false;
                })
                .catch(() => {
                    this.loading.address = false;
                })
        },
        changePassword: function () {
            this.$v.password.$touch();
            if (this.$v.password.$invalid == true) {
                return false;
            }
            this.loading.password = true;
            this.$axios.post("/account/change-password", this.password)
                .then(() =>{
                    this.loading.password = false;
                    this.password = {
                        password: '',
                        confirm_password: '',
                        current_password: '',
                    }
                    this.$v.password.$reset()
                }).catch(() => {
                    this.loading.password = false;
                });
        },
        changeEmail: function () {
            this.$v.email.$touch();
            if (this.$v.email.$invalid == true) {
                return false;
            }
            this.loading.email = true;
            let data = {
                userid: this.userdata.id,
                email: this.email.email,
                password: this.email.current_password,
            };
            this.$axios.post("/account/change-email", data)
                .then(() =>{
                    this.loading.email = false;
                    this.email.email = '';
                    this.email.confirm_email = '';
                    this.email.current_password = '';
                    this.$v.email.$reset()
                }).catch((e) => {
                    console.log(e)
                    this.loading.email = false;
                });
        },
    },
    mounted() {
        this.getData();
    }
};
