//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { modelListMixin } from '~/mixins/modelListMixin'

export default {
    name: 'Favorites',
    mixins: [modelListMixin],
    head: {
        title: 'My Favorites - ESSE',
    },
    data () {
        return {
            loading: {
                data: false,
            },
            favorites: [],
            favoriteToBeRemoved: ''
        }
    },
    methods: {
        getData() {
            this.loading.data = true;
            this.$axios.post("/account/favorites", this.filters)
                .then((res) =>{
                    this.favorites = res.favorites;
                    this.loading.data = false;
                })
                .catch(() =>{
                    this.loading.data = false;
                });
        },
        openRemoveModal(businessId) {
            this.favoriteToBeRemoved = businessId;
            this.$modal.show('removeFavoriteModal');
        },
        closeRemoveModal() {
            this.favoriteToBeRemoved = '';
            this.$modal.hide('removeFavoriteModal');
        },
        removeFavorite() {
            this.loading.data = true;
            if (!this.favoriteToBeRemoved) {
                return false;
            }
            this.$axios.post("/account/favorite/remove", {id: this.favoriteToBeRemoved})
                .then(() =>{
                    this.closeRemoveModal();
                    this.getData();
                    this.loading.data = false;
                })
                .catch(() => {
                    this.loading.data = false;
                });
        }
    },
    mounted() {
        this.getData();
    }
};
