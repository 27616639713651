//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {mapState} from "vuex";
const { required, email } = require('vuelidate/lib/validators');

export default {
    name: "ContactUs.vue",
    head: {
        title: 'Contact - ESSE',
    },
    data: function () {
        return {
            loading: {
                send: false
            },
            contact: {
                customer_id: null,
                customer_name: '',
                email: '',
                message: '',
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
    },
    mounted() {
        if (this.user) {
            this.contact.customer_id = this.user.id;
            this.contact.customer_name = this.user.full_name;
            this.contact.email = this.user.email;
        }
    },
    validations: function() {
        let rules = {
            contact: {
                customer_name: {required},
                email: {required, email},
                message: {required},
            },
        };
        return rules;
    },
    methods: {
        sendContactMessage() {
            this.$v.contact.$touch();
            if (this.$v.contact.$invalid === true) {
                return false;
            }
            this.loading.send = true;
            this.$axios.post("/send-contact-message", this.contact)
                .then( () => {
                    this.loading.send = false;
                    this.contact.message = '';
                })
                .catch( (err)=> {
                    this.loading.send = false;
                    console.log(err)
                })
        }
    }

}
