//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



export default {
    name: 'CardsList',
    head: {
        title: 'Credit cards - ESSE',
    },
    data () {
        return {
            loading: {
                data: false,
                client_secret: false,
                delete: false,
                save: false,
            },
            credit_cards: {},
            credit_card: '',
            client_secret: '',
            stripe_public_key: ''
        }
    },
    created () {
        this.stripe_public_key = process.env.STRIPE_PK
    },
    methods: {
        getData () {
            this.loading.data = true;
            this.$axios.post("/account/credit-cards")
                .then((res) =>{
                    this.credit_cards = res;
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
        addCreditCard() {
            this.credit_card = '';
            this.client_secret = ''
            this.$modal.show('addCreditCardModal');
            this.setupIntent()
        },
        submitSaveCreditCard () {
            this.loading.save = true
            this.$refs.newCard.submit();
        },
        setupIntent() {
            this.loading.client_secret = true
            this.$axios.post("/account/credit-cards/setup-intent")
                .then((res) =>{
                    this.client_secret = res.intent.client_secret
                    this.loading.client_secret = false;
                })
                .catch(() => {
                    this.loading.client_secret = false;
                });
        },
        saveCreditCard(request) {
            this.$axios.post("/account/credit-cards/add", request)
                .then(() =>{
                    this.getData();
                    this.$modal.hide('addCreditCardModal');
                    this.loading.save = false;
                })
                .catch(() => {
                    this.loading.save = false;
                });
        },
        deleteCreditCard(credit_card) {
            this.$dialog.confirm('Are you sure you want to delete the payment card?', {
                okText: 'Yes',
                cancelText: 'No'
            })
            .then(() => {
                this.loading.delete = true;
                this.$axios.post("/account/credit-cards/delete", {credit_card_id: credit_card.id})
                    .then(() =>{
                        this.getData();
                        this.loading.delete = false;
                    })
                    .catch(()=> {
                        this.loading.delete = false;
                    });
            });
        }
    },
    mounted () {
        this.getData();
    }
};
