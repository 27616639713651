import { render, staticRenderFns } from "./MyReviews.vue?vue&type=template&id=a38531ec&"
import script from "./MyReviews.vue?vue&type=script&lang=js&"
export * from "./MyReviews.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Review: require('/Users/tudorserban/Projects/esse-fe/components/Review.vue').default})
