//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'PaymentHistory',
    head: {
        title: 'My Payments history - ESSE',
    },
    data () {
        return {
            loading: {
                data: false,
            },
            payments: {},
            selected_payment: null,
        }
    },
    methods: {
        getData () {
            this.loading.data = true;
            this.$axios.post("/account/payments")
                .then((res) =>{
                    this.payments = res.payments;
                    this.loading.data = false;
                })
                .catch((error) => {
                    this.loading.data = false;
                });
        },
    },
    mounted () {
        this.getData();
    }
};
