import { render, staticRenderFns } from "./BookingHistory.vue?vue&type=template&id=193e295e&"
import script from "./BookingHistory.vue?vue&type=script&lang=js&"
export * from "./BookingHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BookingCard: require('/Users/tudorserban/Projects/esse-fe/components/BookingCard.vue').default,ReviewModal: require('/Users/tudorserban/Projects/esse-fe/components/ReviewModal.vue').default})
