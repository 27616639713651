//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'Notifications',
    head: {
        title: 'Notifications settings - ESSE',
    },
    data () {
        return {
            enable_all: true,

        }
    },
    methods: {
        getData() {
            // this.loading.data = true;
            // this.user.id = this.userdata.id;
            // this.$axios.post("/owners/details", {id: this.userdata.id})
            //     .then((res) =>{
            //         this.user = res.owner;
            //         this.loading.data = false;
            //     })
            //     .catch((error) => {
            //         this.loading.data = false;
            //     });
        },
    },
    mounted() {
        this.getData();
    }
};
