import { render, staticRenderFns } from "./ContactUs.vue?vue&type=template&id=309cf1de&scoped=true&"
import script from "./ContactUs.vue?vue&type=script&lang=js&"
export * from "./ContactUs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "309cf1de",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormInput: require('/Users/tudorserban/Projects/esse-fe/components/FormInput.vue').default,FormTextarea: require('/Users/tudorserban/Projects/esse-fe/components/FormTextarea.vue').default})
