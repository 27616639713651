//
//
//
//
//
//
//


import LayoutMobile from '~/pages/Mobile/LayoutMobile'
import Layout from '~/pages/Desktop/Layout'

export default {
    name: 'App',
    components: { Layout, LayoutMobile },
    // data: function(){
    //     return {
    //         loaded: false
    //     }
    // },
    // mounted: async function () {
    //     this.$events.on('changeLocale', eventData => this.$fetch());
    //     if (this.$fetchState.timestamp <= Date.now() - 30000) {
    //         this.$fetch()
    //     }
    //     await this.getLocation();
    // },
    // beforeDestroy() {
    //     this.$events.$off('changeLocale')
    // },
    // async fetch() {
    //     console.log('fetch', this.$auth.getToken())
    //     await this.getData();
    //     if (this.$auth.getToken()) {
    //         await this.getUser();
    //     }else{
    //         this.loaded = true
    //     }
    // },
    // methods: {
    //     getUser: function () {
    //         return this.$axios.get('/me')
    //             .then((res) =>{
    //                 this.$store.commit('setUser', res.user);
    //                 this.$store.commit('setLoaded', true);
    //                 this.$auth.setRole('client');
    //                 this.$acl.change('client')
    //                 this.loaded = true
    //             })
    //             .catch(() =>{
    //                 this.$store.commit('setLoaded', true);
    //                 this.$auth.setRole('public')
    //                 this.$acl.change('public')
    //                 this.loaded = true;
    //             })
    //     },
    //     getData: function () {
    //         return this.$axios.get('/get-data')
    //             .then((res) => {
    //                 this.$store.commit('setServiceTypes', res.service_types)
    //             })
    //     },
    //     async getLocation () {
    //         await navigator.geolocation.getCurrentPosition(
    //             position => {
    //                 this.$store.commit('setPosition', {
    //                     latitude: position.coords.latitude,
    //                     longitude: position.coords.longitude
    //                 })
    //             },
    //             error => {
    //                 console.log(error.message)
    //             },
    //         )
    //     },
    // }
}
